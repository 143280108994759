<template>
	<div class="myCalss">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>常规</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">我的班级</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="class-list" v-if="ClassList.length">
					<div class="class-item m_b_20" v-for="(item,index) in ClassList" :key="index">
						<div style="padding: 15px 15px 20px 15px;">
							<div class="u-f-item">
								<i class="iconfont icon-banji11"></i>
								<span class="font_blod">{{item.name}}</span>
							</div>
							<div class="u-f-item u-f-jsb m_t_20">
								<div class="u-f-item p-l-25">
									<span class="font_20">{{item.student_num}}</span>
									<span class="font_12" style="color: #A6A6A6;margin-right: 10px;">人</span>
									<el-tag size="small" v-if="item.class_adviser">班主任</el-tag>
								</div>
								<!-- <div class="subject">英语</div> -->
							</div>
						</div>
						<div class="u-f-justify btn" style="padding: 15px 0;background-color: #FAFAFA;color: #505050;" @click="toUrl(item.id)">查看详情</div>
					</div>
				</div>
				<el-empty :image-size="100" v-if="!ClassList.length" style="margin-top: 200px;"></el-empty>
			</div>
		</index>
	</div>
</template>

<script>
	import index from '@/components/common/index.vue';
	export default {
		components: {
			index
		},
		data() {
			return {
				ClassList:[]
			};
		},
		mounted() {
			this.getMyClass()
		},
		methods: {
			getMyClass(){
				this.$api.rule.getMyClass({
					teacher_id:window.localStorage.getItem("id")
				}).then(res=>{
					if(res.data.code==1){
						this.ClassList = res.data.data;
					}
				})
			},
			toUrl(id){
				this.$router.push({
					path: '/myClassDetails', 
					query: {
						id: id
					}
				})
			}
		}
	};
</script>

<style lang="scss">
	.myCalss{
		.content{
			background-color: #FFFFFF;
			height: 80vh;
			margin-top: 1.04166vw;
			padding: 1.302vw;
			.class-list{
				display: flex;
				flex-wrap: wrap;
				.class-item{
					width: 23%;
					border: 1px solid #EBEDEE;
					margin-right: 20px;
					.icon-banji11{
						color: #60B2FF;
						font-size: 26px;
						margin-right: 5px;
					}
					.subject{
						background-color: #F1F1F1;
						font-size: 0.625vw;
						padding: 0.26vw 0.7812vw;
					}
					.btn{
						cursor: pointer;
					}
					.btn:hover{
						background-color: #EEEEEE!important;
					}
				}
				
			}
		}
	}
</style>
